import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { handleNewUser } from '../../../store/actionCreators/authedUser';


const Signup = props => {
    const { t } = useTranslation();
    const { signupModal, closeSignupModal } = props;
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [gender, setGender] = useState('male');
    const url = gender === 'male' ? '/img/av1.svg' : '/img/f-av1.svg';
    const [avatarURL, setAvatarURL] = useState(url);
    const [receiveEmails, toggleSend] = useState(true);
    const [error, setError] = useState('');
    const [alertError, setAlertError] = useState(false);

    const handletoggleSend = e => {
        const checked = e.target.checked;

        if(checked) {
            toggleSend(true)
        } else {
            toggleSend(false)
        }
    }
    
    const handleSubmit = event => {
        event.preventDefault();
        setAlertError(false)
        setError('');
        const form = event.currentTarget;
        // console.log(form.checkValidity())
        setValidated(true);
        if (form.checkValidity() === false && password.length<6 && password!==passwordConfirm) {
            event.stopPropagation();
        }
        if (form.checkValidity() === true && password.length>=6 && password===passwordConfirm) {
            props.dispatch(handleNewUser({email,password,displayName:name,gender,photoURL:avatarURL, receiveEmails}))
            .then((result) => {
                if (result.error===null) {
                    props.closeSignupModal()
                    setEmail(''); setName(''); setPassword(''); setPasswordConfirm('');
                    // props.history.push('/dashboard')
                } else {
                    setError(result.error.message);
                    setAlertError(true);
                }
            })
        }
    }
    
    return (
        <Modal
        show={signupModal}
        onHide={() => {
            setValidated(false);
            closeSignupModal();
        }}
        aria-labelledby="signup-modal"
        className="signup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {t('auth.Sign up as student')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-wrapper">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control 
                            type="email" 
                            placeholder={t('contact.Email address')}
                            required
                            value={email} 
                            onChange={e => setEmail(e.target.value)} 
                            isInvalid={validated && email===''} 
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your email.')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control 
                            type="text" 
                            placeholder={t('contact.Name')}
                            required 
                            value={name}
                            onChange={e => setName(e.target.value)}
                            isInvalid={validated && name===''} 
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your name.')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Control 
                            type="password" 
                            placeholder={t('auth.Password')}
                            required
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            isInvalid={(validated && password.length<6) ? true : false}
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your password')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Control 
                            type="password" 
                            placeholder={t('auth.Confirm password')}
                            required
                            isInvalid={validated && password!==passwordConfirm}
                            value={passwordConfirm} 
                            onChange={e => setPasswordConfirm(e.target.value)}
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Passwords must match')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicTeach">
                        <Form.Label>I can teach *</Form.Label>
                        <Form.Check 
                            type='checkbox'
                            id='pronunciation'
                            label='Quran correct pronunciation'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='arabic'
                            label='Arabic Language'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='memorization'
                            label='Quran memorization'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='tajweed'
                            label='Tajweed'
                        />
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Label>{t('auth.Gender')}</Form.Label>
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Check
                            type="radio"
                            label={t('auth.Male')}
                            className="mr-5"
                            name="gender"
                            value="male"
                            isInvalid={validated && gender===''}  
                            onChange={(e) => setGender(e.target.value)}
                            onClick={() => {setAvatarURL('/img/av1.svg')}}
                            inline
                            checked={gender==='male'}
                        />
                        <Form.Check
                            type="radio"
                            label={t('auth.Female')}
                            name="gender"
                            value="female"
                            isInvalid={validated && gender===''}  
                            onChange={(e) => setGender(e.target.value)}
                            onClick={() => {setAvatarURL('/img/f-av1.svg')}}
                            inline
                            checked={gender==='female'}
                        />
                    </Form.Group>
                    {(gender) === 'male' && (
                    <div className="avatars">
                        <div className="avatars__title">
                            <span>{t('auth.Select Avatar')}</span>
                        </div>
                        <div className="avatars__group">
                            <div 
                                className={(avatarURL==='/img/av1.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='1'
                                onClick={()=> setAvatarURL('/img/av1.svg')}
                            >
                                <img src="/img/av1.svg" alt="avatar 1"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av2.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='2'
                                onClick={()=> setAvatarURL('/img/av2.svg')}
                            >
                                <img src="/img/av2.svg" alt="avatar 2"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av3.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='3'
                                onClick={()=> setAvatarURL('/img/av3.svg')}
                            >
                                <img src="/img/av3.svg" alt="avatar 3"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av4.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='4'
                                onClick={()=> setAvatarURL('/img/av4.svg')}
                            >
                                <img src="/img/av4.svg" alt="avatar 4"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av5.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='5'
                                onClick={()=> setAvatarURL('/img/av5.svg')}
                            >
                                <img src="/img/av5.svg" alt="avatar 5"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av6.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='6'
                                onClick={()=> setAvatarURL('/img/av6.svg')}
                            >
                                <img src="/img/av6.svg" alt="avatar 6"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av7.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='7'
                                onClick={()=> setAvatarURL('/img/av7.svg')}
                            >
                                <img src="/img/av7.svg" alt="avatar 7"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av8.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='8'
                                onClick={()=> setAvatarURL('/img/av8.svg')}
                            >
                                <img src="/img/av8.svg" alt="avatar 8"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/av9.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='9'
                                onClick={()=> setAvatarURL('/img/av9.svg')}
                            >
                                <img src="/img/av9.svg" alt="avatar 9"/>
                            </div>
                        </div>
                    </div>
                    )}
                    {(gender) === 'female' && (
                    <div className="avatars">
                        <div className="avatars__title">
                            <span>{t('auth.Select Avatar')}</span>
                        </div>
                        <div className="avatars__group">
                            <div 
                                className={(avatarURL==='/img/f-av1.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='1'
                                onClick={()=> setAvatarURL('/img/f-av1.svg')}
                            >
                                <img src="/img/f-av1.svg" alt="avatar 1"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av2.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='2'
                                onClick={()=> setAvatarURL('/img/f-av2.svg')}
                            >
                                <img src="/img/f-av2.svg" alt="avatar 2"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av3.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='3'
                                onClick={()=> setAvatarURL('/img/f-av3.svg')}
                            >
                                <img src="/img/f-av3.svg" alt="avatar 3"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av4.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='4'
                                onClick={()=> setAvatarURL('/img/f-av4.svg')}
                            >
                                <img src="/img/f-av4.svg" alt="avatar 4"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av5.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='5'
                                onClick={()=> setAvatarURL('/img/f-av5.svg')}
                            >
                                <img src="/img/f-av5.svg" alt="avatar 5"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av6.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='6'
                                onClick={()=> setAvatarURL('/img/f-av6.svg')}
                            >
                                <img src="/img/f-av6.svg" alt="avatar 6"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av7.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='7'
                                onClick={()=> setAvatarURL('/img/f-av7.svg')}
                            >
                                <img src="/img/f-av7.svg" alt="avatar 7"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av8.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='8'
                                onClick={()=> setAvatarURL('/img/f-av8.svg')}
                            >
                                <img src="/img/f-av8.svg" alt="avatar 8"/>
                            </div>
                            <div 
                                className={(avatarURL==='/img/f-av9.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                id='9'
                                onClick={()=> setAvatarURL('/img/f-av9.svg')}
                            >
                                <img src="/img/f-av9.svg" alt="avatar 9"/>
                            </div>
                        </div>
                    </div>
                    )}
                    <Form.Check
                        type="checkbox"
                        label={t('auth.Do you agree to receive e-mails from us?')}
                        name="send"
                        defaultChecked
                        className="text-left mb-3"
                        onChange={handletoggleSend}
                    />
                    <Button type='submit' className="btn btn--primary">{t('auth.Create account')}</Button>
                </Form>
                <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
            </div>
        </Modal.Body>
      </Modal>
    )
}

function mapStateToProps ({ authedUser }) {
	return {
		authedUser: authedUser
	}
}

export default withRouter(connect(mapStateToProps)(Signup)) 