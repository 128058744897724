import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="body-footer">
            <div className="row">
                <div className="col-md-6 text-center">
                    <Link to="/" className="nav__link">{t('header.Home')}</Link>
                    <Link to="" className="nav__link">{t('header.Blog')}</Link>
                    <Link to="/pricing" className="nav__link">{t('header.Pricing')}</Link>
                    <Link to="" className="nav__link">{t('header.About')}</Link>
                    <Link to="/contact-us" className="nav__link">{t('header.Contact Us')}</Link>
                </div>
                <div className="col-md-6 text-center">
                    {t('home.Hafs Quran')} | {t('header.Al right reserved')}
                </div>
            </div>
        </footer>
    )
}

export default Footer;