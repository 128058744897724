// Routes/index.js
// Top level react routing for the application

// Created by: Karim Essam
// Creation date: 1 January 2021

// External dependencies
import React , { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import LoadingBar from 'react-redux-loading-bar';

// internal imports
import Home from './Home';
import Dashboard from './Dashboard';
import FreeSession from './FreeSession';
import FreeSessionDone from './FreeSessionDone';
import Articles from './Articles';
import ArticleDetails from './Articles/ArticleDetails';
import Pricing from './Pricing';
import Games from './Games';
import FindTeacher from './FindTeacher';
import Contactus from './Contactus';
import history from './history';
import { handleSetAuthedUser } from '../store/actionCreators/authedUser';
import { handleInitialData } from '../store/actionCreators/shared';
import { handleGetHonorBoardStudents } from '../store/actionCreators/honorBoard';
import { handleGetOurTeachers } from '../store/actionCreators/ourTeachers';
import Navigation from './../components/Navigation';
import Footer from './../components/Footer';
import SignUpTeacher from '../components/sign-up-teacher'

import Admin from './Admin'

// Create a Router context and <Switch> component to render first matched <Route>
const Routes = (props) => {
    const { dispatch, t, i18n } = props;

    useEffect(() => {
        dispatch(handleSetAuthedUser());
        dispatch(handleInitialData());
        dispatch(handleGetHonorBoardStudents());
        dispatch(handleGetOurTeachers());
    }, [dispatch]);

    useEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
		document.title = `${t('home.Hafs Quran')}`;
	}, [t, i18n, i18n.language]);

    const i18nDir = i18n.dir()
	useEffect(() => {
		document.body.dir = i18nDir;
	}, [i18nDir]);

    // const renderAdmin = () => (authedUser && authedUser!==null && authedUser.admin)
    //     ? <Route path="/admin" component={Admin} />
    //     : null

    return (
        <Router history={history}>
            <Navigation />
            <LoadingBar />
            <div className="page-container">
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/free-session" component={FreeSession} />
                    <Route path="/free-session-done" component={FreeSessionDone} />
                    <Route path="/articles" exact component={Articles} />
                    <Route path="/articles/:id" component={ArticleDetails} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/games" component={Games} />
                    <Route path="/find-teacher" component={FindTeacher} />
                    <Route path="/contact-us" component={Contactus} />
                    <Route path="/signup-teacher" component={SignUpTeacher} />
                    <Redirect to="/" />
                </Switch>
            </div>
            <Footer />
        </Router>    
    );
}

function mapStateToProps ({ authedUser, loadingBar, language }) {
	return {
        authedUser, loadingBar, language
	}
}

export default withTranslation()(connect(mapStateToProps)(Routes));