import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { handleSignIn, handleResetPassword } from '../../../store/actionCreators/authedUser';


class SignIn extends Component {
  state = {
    email: '',
    password: '',
    error: '',
    alertError: false,
    openResetModal: false,
    alertSuccess: false,
    success: ''
  }

  handleChange = (e) => {
    this.setState(() => ({
        [e.target.name]: e.target.value
    }))
  }

  closeModal = () => {
    this.setState({
      openResetModal: false,
      email: '',
      password: '',
      error: '',
      alertError: false,
      alertSuccess: false,
      success: ''
    })
    this.props.closeLoginModal();
  }

  setOpenResetModal = value => this.setState({openResetModal: value})

  login = (e) => {
    e.preventDefault();
    this.setState(() => ({
      alertError: false,
      error: ''
    }))
    const { email, password } = this.state
    const { dispatch } = this.props
    
    dispatch(handleSignIn(email, password))
    .then((result) => {
      if (result.error===null) {
        this.props.closeLoginModal();
        this.props.history.push('/dashboard')
      } else {
        this.setState(() => ({
          error: result.error.message,
          alertError: true,
        }))
      }
    })
  }

  resetPassword = () => {
    this.setState(() => ({
      email: '',
      error: '',
      alertError: false,
      alertSuccess: false,
      success: ''
    }))
    this.props.dispatch(handleResetPassword(this.state.email))
      .then(() => {
        this.setState(() => ({
          success: 'Reset email sent. Please check your E-mail.',
          alertSuccess: true,
        }))
      })
      .catch(error => {
        this.setState(() => ({
          error: error.message,
          alertError: true,
        }))
      });
  }

  render() {
    const { loginModal, t } = this.props;
    return (
      <Modal
        show={loginModal}
        onHide={this.closeModal}
        aria-labelledby="login-modal"
        className="signup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {t('header.Login')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-wrapper">
                <Form>
                    <Form.Group>
                        <Form.Control type="email" name="email" value={this.state.email} placeholder={t('contact.Email address')} onChange={this.handleChange} required />
                        <span className="required-indicator" role="presentation"></span>
                    </Form.Group>
                    {this.state.openResetModal 
                      ? <>
                          <Button className="btn btn--forget" onClick={() => this.setOpenResetModal(false)}>{t('header.Login')}</Button>
                          <Button onClick={this.resetPassword} className="btn btn--primary" >{t('auth.Reset Password')}</Button>
                        </>
                      : <>
                          <Form.Group>
                            <Form.Control type="password" name="password" value={this.state.password} placeholder={t('auth.Password')} onChange={this.handleChange} required />
                            <span className="required-indicator" role="presentation"></span>
                          </Form.Group>
                          <Button type="button" onClick={() => this.setOpenResetModal(true)} className="btn btn--forget">{t('auth.Forget password')}</Button>
                          <Button onClick={this.login} className="btn btn--primary" >{t('header.Login')}</Button>
                        </>
                    }
                    
                    
                </Form>
            </div>
            <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            <Alert show={this.state.alertSuccess} className="m-2" variant='success'>{this.state.success}</Alert>
        </Modal.Body>
      </Modal>
    )
  }
}

function mapStateToProps ({ authedUser }) {
	return {
		authedUser: authedUser
	}
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SignIn)));