import React , { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, withRouter } from 'react-router-dom';
import { Modal, Alert, Nav } from 'react-bootstrap';
import HomePage from './home';
import Users from './users';
import FreeSessions from './freeSessions';
import ContactUs from './contactUs';
import Classes from './classes';
import NewClass from './NewClass';
import Invoices from './invoices';
import Articles from './articles/index';
import TeachingRequeasts from './teachingRequests/index';
import Videos from './videos/index';
import Audios from './audios/index';

import { handleGetAllUsers } from '../../store/actionCreators/users';
import { handleGetAllClasses } from '../../store/actionCreators/classes';
import { handleReceiveFreeSessions } from '../../store/actionCreators/freeSession';
import { handleReceiveContactUsMessages } from '../../store/actionCreators/conactUs';
import { handleReceiveAllInvoices } from '../../store/actionCreators/invoices';
import { handleRecieveTeachingRequests } from '../../store/actionCreators/teachingRequeasts';
import EditClass from '../Dashboard/MainDashboardPage/EditClass';

const AdminSidebar = () => (
    <div className="dashboard__sidebar">
        <Nav className="flex-column" >
            <Nav.Item>
                <NavLink className="nav-link" to='/admin/home'>
                    Home Page
                </NavLink>
                <NavLink className="nav-link" to='/admin/users'>
                    Users
                </NavLink>
                <NavLink className="nav-link" to='/admin/free-session'>
                    Free Session
                </NavLink>
                <NavLink className="nav-link" to='/admin/contact-us'>
                    Contact us
                </NavLink>
                <NavLink className="nav-link" to='/admin/classes'>
                    Classes
                </NavLink>
                <NavLink className="nav-link" to='/admin/articles'>
                    Articles
                </NavLink>
                <NavLink className="nav-link" to='/admin/videos'>
                    Videos
                </NavLink>
                <NavLink className="nav-link" to='/admin/audios'>
                    Audios
                </NavLink>
                <NavLink className="nav-link" to='/admin/invoices'>
                    Invoices
                </NavLink>
                <NavLink className="nav-link" to='/admin/teaching-requests'>
                    Teaching Requests
                </NavLink>
            </Nav.Item>
        </Nav>
    </div>
);

class AdminPage extends Component {
    state = {
        openSignModal: true,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authedUser !== this.props.authedUser) {
            if (this.props.authedUser && this.props.authedUser.admin) {
                this.props.dispatch(handleGetAllUsers())
                this.props.dispatch(handleGetAllClasses())
                this.props.dispatch(handleReceiveFreeSessions())
                this.props.dispatch(handleReceiveContactUsMessages())
                this.props.dispatch(handleReceiveAllInvoices())
                this.props.dispatch(handleRecieveTeachingRequests())
            } 
        }
    }

    url = () => {
        if (this.props.authedUser && this.props.authedUser.admin) {
            return (
                <>
                    <Route path="/admin/home" component={HomePage}/>
                    <Route path="/admin/users" component={Users}/>
                    <Route path="/admin/free-session" component={FreeSessions}/>
                    <Route path="/admin/contact-us" component={ContactUs}/>
                    <Route path="/admin/classes" component={Classes} exact />
                    <Route path="/admin/new-class" component={NewClass} />
                    <Route path="/admin/classes/:classId" component={EditClass} />
                    <Route path="/admin/Invoices" component={Invoices}/>
                    <Route path="/admin/articles" component={Articles}/>
                    <Route path="/admin/teaching-requests" component={TeachingRequeasts}/>
                    <Route path="/admin/videos" component={Videos}/>
                    <Route path="/admin/audios" component={Audios}/>
                </>
            )
        } else {
            if (!this.props.loadingBar.default) {
                const closeSignModal = () => {
                    this.setOpenSignModal(false);
                    this.props.history.replace("/");
                }
                return (
                    <SignModal
                        signModal={this.state.openSignModal}
                        closeSignModal={closeSignModal}
                    />
                )
            } else {
                return <img alt="loading" src="./img/45.svg" />
            }
        }
    }

    setOpenSignModal = value => this.setState({openSignModal: value})
      
    render() {
        // console.log(this.props)
        return (
            <div className="dashboard admin">    
                <div className='dashboard-page admin-page dashboard-with-sidebar row'>
                    <div className="sidebar col-lg-3 m-2">
                        <AdminSidebar />
                    </div>
                    
                    <div className="page-content ml-4 col-lg-8">
                        {this.url()}
                    </div>
                </div>
            </div>    
        )
    }
}

function mapStateToProps ({ authedUser, loadingBar }) {
	return {
        authedUser,
        loadingBar
	}
}

export default withRouter(connect(mapStateToProps)(AdminPage));

const SignModal = props => {

    const closeModal = () => props.closeSignModal()

    return (
        <Modal
            show={props.signModal}
            onHide={closeModal}
            aria-labelledby="sign-modal"
            className="signup"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center"><Alert variant="warning">You have to login first<br/>
                    Or you are not an Admin.
                </Alert></h6>
            </Modal.Body>
        </Modal>
    )
}